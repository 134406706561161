import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// import Seo from '../components/seo'
// import Layout from '../components/layout'
// import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class TagTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulTag')
    const blogs = get(this.props, 'data.allContentfulBlogPost')
    const authorBlogs = blogs.edges.map((element: { node: any }) => {return element.node})

    return (
        <div>
            <h1>{post.name}</h1>
            <ArticlePreview posts={authorBlogs} />
        </div>
    )
  }
}

export default TagTemplate

//need to find out what Slug is - can I change it to/from lower case and with dashes
export const pageQuery = graphql`
  query TagBySlug(
    $contentfulId: String!
  ) {
    contentfulTag(contentful_id: { eq: $contentfulId }) {
      name
      contentful_id
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
      metadata: {
        tags: {
          elemMatch: {contentful_id: { eq: $contentfulId }}
        }
      }
    }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          image: heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          description {
            raw
          }
          metadata {
            tags {
              name
            }
          }
        }
      }
    }
  }
`
